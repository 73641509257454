const inAppScannerRoutes = [
    {
        path: "/json-builder",
        name: "jsonBuilder",
        component: () => import("@/modules/in-app-scanner/index.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "",
            subject: ""
        }
    }
]

export default inAppScannerRoutes
