import { createRouter, createWebHistory } from "vue-router"
import dashboardRoutes from "@/modules/dashboard/route"
import userRoutes from "@/modules/user/route"
import authRoutes from "@/modules/auth/authRoute"
import { useAuthStore } from "@/modules/auth/authStore"
import branchRoutes from "@/modules/branch/route"
import pinRoutes from "@/modules/pin/route"
import pocketAdminRoutes from "@/modules/pocket-admin/route"
import { canNavigate } from "@/libs/acl/routeProtection"
import membershipRoutes from "@/modules/membership/route"
import notificationRoutes from "@/modules/notification/route"
import couponRoutes from "@/modules/coupon/route"
import roleRoutes from "@/modules/role/route"
import brandRoutes from "@/modules/brand/route"
import appConfigurationRoutes from "@/modules/app-configuration/route"
import reviewsRoutes from "@/modules/reviews/route"
import luckyDrawWebAppRoutes from "@/modules/lucky-draw-web-app/route"
import raffleRoutes from "@/modules/campaign/raffle/route"
import activityLogsRoutes from "@/modules/activity-logs/route"
import inAppScannerRoutes from "@/modules/in-app-scanner/route"

const routes = [
    ...dashboardRoutes,
    ...authRoutes,
    ...userRoutes,
    ...branchRoutes,
    ...pinRoutes,
    ...pocketAdminRoutes,
    ...membershipRoutes,
    ...notificationRoutes,
    // ...bannerRoutes,
    ...couponRoutes,
    ...roleRoutes,
    ...brandRoutes,
    ...appConfigurationRoutes,
    ...reviewsRoutes,
    ...luckyDrawWebAppRoutes,
    ...raffleRoutes,
    ...activityLogsRoutes,
    ...inAppScannerRoutes,
    {
        path: "/error-404",
        name: "error404",
        component: () => import("@/pages/NotFound.vue"),
        meta: {
            layout: "full"
        }
    },
    {
        path: "/not-authorized",
        name: "not-authorized",
        component: () => import("@/pages/Access.vue"),
        meta: {
            layout: "full"
        }
    },
    {
        path: "/:catchAll(.*)*",
        redirect: "error-404",
        meta: {
            layout: "full"
        }
    },
    {
        path: "/maintenance",
        name: "maintenance",
        component: () => import("@/pages/Maintenance.vue"),
        meta: {
            layout: "full"
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    mode: "history",
    // Simulate native-like scroll behavior when navigating to a new
    // route and using back/forward buttons.
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0, left: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)

    if (to.matched.some((record) => record.meta.requireAuth)) {
        const authStore = useAuthStore()
        const isLoggedIn = authStore.isAuth

        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: "login" })

        // If logged in => not authorized
        if (!canNavigate(to)) {
            return next({ name: "not-authorized" })
        }
        // Redirect if logged in
        // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        //     return next({ name: "dashboard" })
        // }
        // if (authStore.getUserData.email == "digital@pocket.com.mm") {
        //     if (
        //         to.name == "notificationSend" ||
        //         to.name == "notificationBulkImport"
        //     ) {
        //         return next()
        //     } else {
        //         return next({ name: "not-authorized" })
        //     }
        // } else {
        //     return next()
        // }
        return next()
    } else {
        return next()
    }
})

export default router
