export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မြန်မာ"])},
  "userModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Module"])},
  "userList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User List"])},
  "pocketAdminList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pocket Admin List"])},
  "merchantUserList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant User List"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modules"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "subscriptionList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription List"])},
  "branchModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Module"])},
  "branchList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch List"])},
  "pinList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin List"])},
  "brandList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand List"])},
  "roleModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Module"])},
  "membershipModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Module"])},
  "bannnerModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner Module"])},
  "brandModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand Module"])},
  "couponModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon Module"])},
  "notificationModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Module"])},
  "sendNoti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Noti"])},
  "Notification List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification List"])},
  "sendBulkNoti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Bulk Noti"])},
  "appConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Configuration"])},
  "rewardsBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward Balance Visibility"])},
  "reviews&ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews & Ratings"])},
  "luckyDrawModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucky Draw"])},
  "luckyDrawList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucky Draw List"])},
  "luckyDrawWebAppModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucky Draw Web App"])},
  "campaignModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Module"])},
  "raffleCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raffle Campaign"])},
  "activityLogsModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Logs"])},
  "dashboardMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Menu"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
  "userFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Frequency"])},
  "performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
  "campaignPerformance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Performance"])},
  "branchPerformance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Performance"])},
  "JsonBuilder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Json Builder"])}
}