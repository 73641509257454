import { Ability } from "@casl/ability"
import { merchantUserAbility } from "./config"

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

let existingAbility = []
if (localStorage.getItem("userData")) {
    const user = JSON.parse(localStorage.getItem("userData"))
    // console.log(user.user_type)

    if (user.user_type == "Dashboard") {
        let adminAbilities = JSON.parse(localStorage.getItem("userAbility"))

        adminAbilities.push({
            action: "manage",
            name: "manage pocketadmin",
            subject: "pocketadmin"
        })
        existingAbility = adminAbilities
    } else {
        let abilities = JSON.parse(localStorage.getItem("userAbility"))
        var merchantUserPermissions = merchantUserAbility

        const namesToCheck = [
            "Manage Report",
            "View Transaction",
            "View User Frequency",
            "View Campaign Performance",
            "View Branch Performance"
        ]

        // Get matching permissions
        const reportPermissions = abilities.filter((permission) =>
            namesToCheck.includes(permission.name)
        )
        var permissions = []
        if (user.role == "branch-manager") {
            permissions = reportPermissions
        } else {
            if (user.role == "merchant-cashier") {
                merchantUserPermissions = merchantUserPermissions.filter(
                    (item) =>
                        item.subject !== "json-builder" &&
                        item.subject !== "activity-log-list"
                )
            }
            permissions = [...merchantUserPermissions, ...reportPermissions]
        }
        existingAbility = permissions
    }

    // if (user.email == "digital@pocket.com.mm") {
    //     console.log(user.email)
    //     existingAbility = [
    //         {
    //             action: "send",
    //             subject: "noti"
    //         }
    //     ]
    // }

    // console.log(existingAbility)
}

export default new Ability(existingAbility)
